/*------ 24. login register Page  --------*/

.login-register-wrapper {
  .login-register-tab-list {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    .nav-item {
      position: relative;
      a {
        background: none;
        padding: 0;

        h4 {
          font-size: 25px;
          font-weight: 700;
          margin: 0 20px;
          text-transform: capitalize;
          transition: all 0.3s ease 0s;
        }
        &:last-child::before {
          display: none;
        }
      }
      a.active h4 {
        color: $theme-color;
      }

      &:before {
        background-color: #454545;
        bottom: 5px;
        content: "";
        height: 18px;
        margin: 0 auto;
        position: absolute;
        right: -2px;
        transition: all 0.4s ease 0s;
        width: 1px;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }
  .login-form-container {
    background: #F4F4F4;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    padding: 80px;
    text-align: left;
    @media #{$lg-layout} {
      padding: 80px 50px;
    }
    @media #{$xs-layout} {
      padding: 40px 15px;
    }
    .login-register-form {
      form {
        input {
          background-color: #FFF;
          border: 1px solid #ebebeb;
          color: #333;
          font-size: 14px;
          height: 45px;
          margin-bottom: 30px;
          padding: 0 15px;
        }
        input[type="checkbox"] {
          height: 15px;
          margin: 0;
          position: relative;
          top: 1px;
          width: 17px;
        }
        .button-box {
          .login-toggle-btn {
            padding: 10px 0 19px;
            label {
              color: #242424;
              font-size: 13px;
              font-weight: 400;
              @media #{$xs-layout} {
                font-size: 12px;
              }
            }
            a {
              color: #242424;
              float: right;
              font-size: 13px;
              @media #{$xs-layout} {
                font-size: 12px;
              }
              transition: all 0.3s ease 0s;
              &:hover {
                color: $theme-color;
              }
            }
          }
          .login-btn,
          button {
            width: 100%;
            background-color: $theme-color;
            border: medium none;
            border-radius: 5px;
            color: #fff;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            line-height: 1;
            padding: 11px 30px;
            text-transform: uppercase;
            transition: all 0.3s ease 0s;
            &:hover {
              background-color: #f2f2f2;
              color: #333;
            }
          }
          .login-btn {
            margin: 20px 0px;
          }
        }
      }
    }
  }
  .no-shadow {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  }
}

.login-register-area {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}

.register-modal {
  .modal-dialog {
    padding: 0;
    margin: 5% auto;
    max-width: 500px;
  }
  .modal-content {
    background-color: #F4F4F4;
  }
  .modal-header {
    border-bottom: 0;
  }
}

.forget-password-modal {
  .modal-dialog {
    padding: 0;
    margin: 5% auto;
    max-width: 500px;
  }
  .modal-content {
    background-color: #F4F4F4;
  }
  .modal-header {
    border-bottom: 0;
  }
}
